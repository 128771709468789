<template>
  <div v-loading="loading">
    <en-table-layout :tableData="pageData.data" @selection-change="orderListSelectionChange" :row-key="getRowKeys"
      :span-method="spanMethod" ref="enTableLayout1">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="setType">
          <el-tab-pane label="销售订单核销" name="1"></el-tab-pane>
          <el-tab-pane label="销售退单核销" name="2"></el-tab-pane>
          <el-tab-pane label="来往对账核销" name="3"></el-tab-pane>
          <el-tab-pane label="充值销售单核销" name="4"></el-tab-pane>
          <el-tab-pane label="充值退款单核销" name="5"></el-tab-pane>
          <el-tab-pane label="新来往对账核销" name="6"></el-tab-pane>
        </el-tabs>
      </template>
      <template v-if="activeName == 1" slot="toolbar">
        <el-form-item label="付款状态" class="col-auto">
          <el-select style="width: 140px" size="medium" v-model="advancedForm.pay_status" placeholder="请选择" clearable>
            <el-option v-for="item in payType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_status" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in tradeType" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 1" slot="toolbar_btn">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in filterdouble" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_source" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="企业后台" value="1"></el-option>
            <el-option label="移动端前台" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option label="销售单据编号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="IntervalCheck === '1' || IntervalCheck === '2'" @click="advancedSearchEvent"
            type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 1" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column prop="sell_refund_record_sn" align="center" label="销售单据编号" width="160" fixed="left" />
        <el-table-column prop="shop_name" label="所属商城" align="center" width="160" show-overflow-tooltip />
        <el-table-column align="center" prop="card_num" label="需开卡总数" width="100" />
        <el-table-column align="center" prop="open_card_num" label="已开卡数量" width="100" />
        <el-table-column align="center" prop="total_payable_price" label="应收金额" width="80" />
        <el-table-column align="center" prop="curr_payable_price" label="已收金额" width="80" />
        <el-table-column align="center" label="余额" width="80">
          <template slot-scope="{ row }">
            {{ row.total_payable_price | subtraction(row.curr_payable_price) }}
          </template>
        </el-table-column>
        <el-table-column prop="client_name" align="center" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
    row.create_time | unixToDate
  }}</template>
        </el-table-column>
        <el-table-column align="center" prop="pay_status" label="付款状态" width="80">
          <template slot-scope="{ row }">
            {{ getPayTypeName(row.pay_status) }}
            <p v-if="row.total_payable_price < row.curr_payable_price">
              (超付)
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="trade_status" label="单据状态" width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="trade_source" label="单据来源" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.trade_source == 1 ? '企业后台' : '移动端前台' }}</template>
        </el-table-column>
        <el-table-column prop="payable_remark" label="收款备注" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.trade_status == 0 || row.trade_status == 1" type="primary" size="mini"
              @click="cancelAterVerification(row, 1)">核销</el-button>
            <el-button v-if="!(row.trade_status === 3 || row.open_card_status === 1)" type="primary" size="mini"
              @click="getCloseCardsTypeChange(row)">开卡</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 1" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>


      <template v-if="activeName == 2" slot="toolbar">
        <el-form-item label="退款状态" class="col-auto">
          <el-select style="width: 140px" size="medium" v-model="advancedForm.refund_status" placeholder="请选择"
            clearable>
            <el-option v-for="item in refundType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in tradeType" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 2" slot="toolbar_btn">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in cardTypes" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_source" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="企业后台" value="1"></el-option>
            <el-option label="移动端前台" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option label="销售退单编号" value="sn"></el-option>
              <el-option label="关联销售单号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 2" slot="table-columns">
        <el-table-column align="center" type="selection" :reserve-selection="true" fixed />
        <el-table-column prop="sn" align="center" label="销售退单编号" width="160" fixed="left" />
        <el-table-column align="center" prop="sell_refund_record_sn" label="关联销售单号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip />
        <el-table-column prop="refund_num" align="center" label="需退卡总数" width="120">
          <template slot-scope="{ row }">
            <p>{{ row.refund_num || row.card_num || 0 }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="已退卡数量" width="100">
          <template slot-scope="{ row }">
            {{ row.refund_card_num || row.curr_refund_num || 0 }}
          </template></el-table-column>
        <el-table-column align="center" prop="after_discount_price" label="应退金额" width="80" />
        <el-table-column align="center" prop="refunded_price" label="已退金额" width="80" />
        <el-table-column align="center" prop="client_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
    row.create_time | unixToDate
  }}</template>
        </el-table-column>
        <el-table-column align="center" prop="refund_status" label="退款状态" width="80">
          <template slot-scope="{ row }">
            {{ getRefundTypeName(row.refund_status) }}
            <p v-if="row.after_discount_price < row.refunded_price">
              (超退)
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="单据状态" width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.status) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="trade_source" label="单据来源" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.trade_source == 1 ? '企业后台' : '移动端前台' }}</template>
        </el-table-column>
        <el-table-column prop="refund_remark" label="退款备注" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.status == 0 || row.status == 1" type="primary" size="mini"
              @click="cancelAterVerification(row, 2)">核销</el-button>
            <el-button v-if="!(row.status === 3 || row.close_card_status === 1)" type="primary" size="mini"
              @click="getCloseCardsTypeChange(row)">退卡</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 2" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>

      <template v-if="activeName == 3" slot="toolbar">
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in cardTypes" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 3" slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option label="销售单据编号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 3" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column align="center" prop="sell_refund_record_sn" label="销售单据编号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip />
        <el-table-column align="center" prop="card_num" label="需开卡总数" width="100" />
        <el-table-column align="center" prop="refund_num" label="需退卡总数" width="100" />
        <el-table-column align="center" prop="open_card_num" label="已开卡数量" width="100" />
        <el-table-column align="center" prop="curr_refund_num" label="已退卡数量" width="100" />
        <el-table-column align="center" prop="total_payable_price" label="应收金额" width="80" />
        <el-table-column align="center" prop="curr_payable_price" label="已收金额" width="80" />
        <el-table-column align="center" prop="total_refund_price" label="应退金额" width="80" />
        <el-table-column align="center" prop="curr_refund_price" label="已退金额" width="80" />
        <el-table-column align="center" prop="balance" label="余额" width="80" />
        <el-table-column align="center" prop="client_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
    row.create_time | unixToDate
  }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" width="150" show-overflow-tooltip />
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="() => {
      remarkDialog.display();
      cancelAterVerificationForm = row;
      remark = row.remark;
    }
    ">备注</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 3" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>


      <template v-if="activeName == 4" slot="toolbar">
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_status" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="未核销" :value="0"></el-option>
            <el-option label="已核销" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="来源应用" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.shop_type" size="medium" clearable>
            <el-option label="全部" value="" />
            <el-option label="现金商城" value="1" />
            <el-option label="双选商城" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_source" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="企业后台" :value="1"></el-option>
            <el-option label="移动端前台" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 4" slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option label="充值销售单号" value="recharge_sn"></el-option>
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="销售人员名称" value="seller_name"></el-option>
              <el-option label="制单人名称" value="operator_name"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 4" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed="left" />
        <el-table-column align="center" show-overflow-tooltip prop="sn" label="充值销售单号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip fixed="left" />
        <el-table-column align="center" prop="recharge_number" label="充值人数" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="discounts_total_money" label="应收金额" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="open_card_num" label="已收金额" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.trade_status == 0 ? 0 : row.trade_status == 3 ? 0 :
    row.discounts_total_money }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="shop_type" label="来源应用" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.shop_type == 1 ? '现金商城' : '双选商城' }} </template>
        </el-table-column>
        <el-table-column align="center" prop="customer_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="trade_status" label="单据状态" width="80" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="seller_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="contacts_person" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="operator_name" label="制单人" width="80" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据日期" width="150" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column align="center" prop="trade_source" label="单据来源" width="80" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.trade_source == 1 ? "企业后台" : "移动端前台" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.trade_status == 0" type="primary" size="mini"
              @click="cancelAterVerification(row, 4)">核销</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 4" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>


      <template v-if="activeName == 5" slot="toolbar">
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_status" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="未核销" :value="0"></el-option>
            <el-option label="已核销" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="来源应用" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.shop_type" size="medium" clearable>
            <el-option label="全部" value="" />
            <el-option label="现金商城" :value="1" />
            <el-option label="双选商城" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_source" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="企业后台" :value="1"></el-option>
            <el-option label="移动端前台" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 5" slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option label="充值退款单号" value="recharge_sn"></el-option>
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="销售人员名称" value="seller_name"></el-option>
              <el-option label="制单人名称" value="operator_name"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 5" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed="left" />
        <el-table-column align="center" show-overflow-tooltip prop="sn" label="充值退款单号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip fixed="left" />
        <el-table-column align="center" prop="recharge_number" label="扣款人数" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="discounts_total_money" label="应退金额" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="open_card_num" label="已退金额" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.trade_status == 0 ? 0 : row.trade_status == 3 ? 0 :
    row.discounts_total_money }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="shop_type" label="来源应用" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.shop_type == 1 ? '现金商城' : '双选商城' }} </template>
        </el-table-column>
        <el-table-column align="center" prop="customer_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="trade_status" label="单据状态" width="80" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="seller_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="contacts_person" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="operator_name" label="制单人" width="80" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据日期" width="150" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column align="center" prop="trade_source" label="单据来源" width="80" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.trade_source == 1 ? "企业后台" : "移动端前台" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.trade_status == 0 || row.trade_status == 1" type="primary" size="mini"
              @click="cancelAterVerification(row, 5)">核销</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 5" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>


      <template v-if="activeName == 6" slot="toolbar">
        <el-form-item label="日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期" defaule-value="defaultValue"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(
      /\s+/g,
      ''
    )
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" style="width: 130px">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="销售人员名称" value="salesman_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == 6" slot="table-columns">
        <el-table-column align="center" key="no" prop="no" label="客户编号" fixed="left" />
        <el-table-column align="center" key="customer_name" prop="customer_name" label="客户名称" fixed="left"
          show-overflow-tooltip />
        <el-table-column align="center" key="sell_name" prop="sell_name" label="所属销售人员" fixed="left" />
        <el-table-column align="center" key="bill_counts" prop="bill_counts" label="单据总数" width="100">
          <template slot-scope="{ row }">{{ row.bill_counts || 0 }}</template>
        </el-table-column>
        <el-table-column align="center" key="account_accumulated_receivable" prop="account_accumulated_receivable"
          label="累计应收" width="100">
          <template slot-scope="{ row }">{{ row.account_accumulated_receivable || 0 }}</template>
        </el-table-column>
        <el-table-column align="center" key="account_accumulated_received" prop="account_accumulated_received"
          label="累计已收" width="100">
          <template slot-scope="{ row }">{{ row.account_accumulated_received || 0 }}</template>
        </el-table-column>
        <el-table-column align="center" key="account_accumulated_refunds" prop="account_accumulated_refunds"
          label="累计应退" width="80">
          <template slot-scope="{ row }">{{ row.account_accumulated_refunds || 0 }}</template>
        </el-table-column>
        <el-table-column align="center" key="account_accumulated_refunded" prop="account_accumulated_refunded"
          label="累计已退" width="80">
          <template slot-scope="{ row }">{{ row.account_accumulated_refunded || 0 }}</template>
        </el-table-column>

        <el-table-column align="center" key="left_deposit" prop="left_deposit" label="余额" width="80">
          <template slot-scope="{ row }">{{ row.left_deposit || 0 }}</template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="handleDetail(row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 6" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>

    </en-table-layout>



    <x-dialog :proxy="recordDialog">
      <el-table :header-cell-style="{
    textAlign: 'center',
    backgroundColor: 'rgba(230, 236, 247, 1)',
  }" style="line-height: 1" :data="recordTable" border>
        <el-table-column label="编号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="核销金额" prop="price" show-overflow-tooltip></el-table-column>
        <el-table-column :label="[1, '1', 4, '4'].includes(this.activeName) ? '收款账户' : '退款账户'" prop="price" width="200"
          show-overflow-tooltip>
          <template slot-scope="{row}">
            公司名称：{{ row.company_name }}<br />
            开户银行：{{ row.bank_name }}<br />
            银行账户：{{ row.bank_account }}
          </template>
        </el-table-column>
        <el-table-column label="核销时间" show-overflow-tooltip width="180">
          <template slot-scope="{ row }">{{
    row.create_time | unixToDate
  }}</template>
        </el-table-column>
        <el-table-column label="操作人员" prop="user_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="收款备注" prop="remark" show-overflow-tooltip></el-table-column>
      </el-table>
    </x-dialog>

    <x-dialog :proxy="operDialog">
      <el-form label-width="140px" :model="cancelAterVerificationForm" :rules="rules" ref="cancelAterVerificationForm">
        <h3>客户账户信息:</h3>
        <el-form-item style="margin-bottom: 0px" label="客户名称">{{ customerData.name }}</el-form-item>
        <template v-if="customerData.showzedDiscount">
          <el-form-item style="margin-bottom: 0px" label="项目名称(商城名称)" label-width="140px">{{
    customerData.showzedDiscount.shop_name
  }}</el-form-item>
          <el-form-item style="margin-bottom: 0px" label="项目总金额">{{ customerData.showzedDiscount.total_amount || 0
            }}元</el-form-item>
          <el-form-item style="margin-bottom: 0px" label="剩余未结算">{{ customerData.showzedDiscount.residue_amount || 0
            }}元</el-form-item>
          <el-form-item style="margin-bottom: 0px" label="单据已核销总金额">
            {{ customerData.showzedDiscount.audited_amount }}元
          </el-form-item>
          <el-form-item style="margin-bottom: 0px" label="项目结算次数">{{ customerData.showzedDiscount.settlement_times || 0
            }}次</el-form-item>
        </template>
        <template v-else>
          <el-form-item style="margin-bottom: 0px" label="账期类型">{{ customerData.account_period ? '有账期' : '无账期'
            }}</el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item style="margin-bottom: 0px" v-if="customerData.account_period" label="账期额度剩余">{{
    customerData.account_period_credit_limit_left }}元</el-form-item>
              <el-form-item style="margin-bottom: 0px; width:100%" v-else label="账户余额">{{
    customerData.left_deposit
  }}元</el-form-item>
            </el-col>
            <el-col :span="12" v-if="customerData.account_period">
              <el-form-item style="margin-bottom: 0px" label="实收款">{{ customerData.left_deposit
                }}元</el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="customerData.account_period">
            <el-col :span="12">
              <el-form-item style="margin-bottom: 0px" label="本次账期应收款">{{
    customerData.current_account_period_receivable
  }}元</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item style="margin-bottom: 0px" label="上次账期应收款">{{
    customerData.pre_account_period_receivable
  }}元</el-form-item>
            </el-col>
          </el-row>
        </template>
        <h3>{{ ['1', '4'].includes(this.activeName) ? '收款' : '退款' }}账户:</h3>
        <el-form-item label="公司名称" prop="company_name">
          <el-select style="width: 140px" size="medium" v-model="cancelAterVerificationForm.company_name"
            @change="getCompanyName" placeholder="请选择">
            <el-option v-for="item in companyOption" :key="item.id" :label="item.company_name"
              :value="item.company_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank_name">
          <el-select style="width: 140px" size="medium" v-model="cancelAterVerificationForm.bank_name"
            @change="getBanckAccount" placeholder="请选择">
            <el-option v-for="(item, index) in openingBankOption" :key="index" :label="item.bank_name"
              :value="item.bank_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行账号" prop="bank_account">
          <el-select style="width: 140px" size="medium" v-model="cancelAterVerificationForm.bank_account"
            placeholder="请选择">
            <el-option v-for="(item, index) in bankAccountOption" :key="index" :label="item.bank_account"
              :value="item.bank_account"></el-option>
          </el-select>
        </el-form-item>
        <h3>单据核销:</h3>
        <div v-if="['1', '4'].includes(this.activeName)">
          <el-form-item style="width:50%;margin-bottom:0" label="单据应收金额">
            <span v-if="this.activeName == 1">{{ cancelAterVerificationForm.total_payable_price }}</span>
            <span v-else>{{ cancelAterVerificationForm.discounts_total_money }}</span>元
          </el-form-item>
          <el-form-item style="width:50%;margin-bottom:0" label="单据已收金额">
            <span v-if="this.activeName == 1">{{ cancelAterVerificationForm.curr_payable_price }}</span>
            <span v-else>{{ [0, 3].includes(cancelAterVerificationForm.trade_status) ? 0 :
    cancelAterVerificationForm.discounts_total_money
              }}</span>元
          </el-form-item>
        </div>
        <div v-if="['2', '5'].includes(this.activeName)">
          <el-form-item style="width:50%;margin-bottom:0" label="单据应退金额">
            <span v-if="this.activeName == 2">{{ cancelAterVerificationForm.after_discount_price }}</span>
            <span v-else>{{ cancelAterVerificationForm.discounts_total_money }}</span>
          </el-form-item>
          <el-form-item style="width:50%;margin-bottom:0" label="单据已退金额">
            <span v-if="this.activeName == 2">{{ cancelAterVerificationForm.curr_refund_price }}</span>
            <span v-else> {{ [0, 3].includes(cancelAterVerificationForm.trade_status) ? 0 :
    cancelAterVerificationForm.discounts_total_money
              }}</span>元
          </el-form-item>
        </div>
        <el-form-item label="核销金额" prop="change_price">
          <el-input style="width: 200px" size="medium" :controls="false"
            v-model="cancelAterVerificationForm.change_price" :disabled="['4', '5'].includes(activeName)" type="text"
            placeholder="请输入需要核销的金额" @blur="checkPrice" @input="changePrice"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="备注信息">
          <el-input style="width: 350px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.payable_remark" :rows="5" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" label="备注信息">
          <el-input style="width: 350px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.refund_remark" :rows="5" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
        <el-form-item v-if="['4', '5'].includes(this.activeName)" label="备注信息">
          <el-input style="width: 350px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.remark" :rows="5" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>

    <x-dialog :proxy="operCardDialog">
      <el-table :header-cell-style="{
    textAlign: 'center',
    backgroundColor: 'rgba(230, 236, 247, 1)',
  }" style="line-height: 1" :data="cardList" :span-method="cardSpanMethod" border>
        <el-table-column label="所属商城" width="230" prop="shop_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="卡券名称" width="200" show-overflow-tooltip prop="card_name"></el-table-column>
        <el-table-column label="卡号区间" prop="price">
          <template slot-scope="{ row }">
            <span>{{ row.card_code_start + "~" + row.card_code_end }}</span>
          </template>
        </el-table-column>
      </el-table>
    </x-dialog>

    <x-dialog :proxy="remarkDialog">
      <div style="padding: 10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入100字以内的备注信息" maxlength="100"
          v-model="remark"></el-input>
      </div>
    </x-dialog>
    <x-dialog :proxy="oldOperDialog">
      <el-form label-width="120px" :model="cancelAterVerificationForm" :rules="rules" ref="cancelAterVerificationForm"
        class="c-a-form">
        <el-form-item label="客户名称">{{
    cancelAterVerificationForm.client_name
  }}</el-form-item>
        <el-form-item v-if="activeName === '1'" label="应收金额">{{
    cancelAterVerificationForm.total_payable_price
  }}</el-form-item>
        <el-form-item v-if="activeName === '1'" label="已收金额">{{
    cancelAterVerificationForm.curr_payable_price
  }}</el-form-item>
        <el-form-item v-if="activeName === '2'" label="应退金额">{{
    cancelAterVerificationForm.after_discount_price
  }}</el-form-item>
        <el-form-item v-if="activeName === '2'" label="已退金额">{{
    cancelAterVerificationForm.curr_refund_price
  }}</el-form-item>
        <el-form-item label="核销金额" prop="change_price">
          <el-input style="width: 200px" size="medium" :controls="false"
            v-model="cancelAterVerificationForm.change_price" type="text" placeholder="请输入需要核销的金额" @blur="checkPrice"
            @input="changePrice"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="收款备注" prop="link_name">
          <el-input style="width: 300px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.payable_remark" :rows="3" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" label="退款备注" prop="link_name">
          <el-input style="width: 300px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.refund_remark" :rows="3" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_Login from "@/api/login";
import { handleDownload, downloadExcel } from "@/utils";
import { Foundation, RegExp } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import { getCustomerAccountVerificationList, getCustomerAll, getEcaList, getCustomerAccount } from "@/api/settingCustomer";
import { getRechargeRefundSalesList, rechargeUnderWrite, checkMember } from "@/api/saleCard";
import {
  changeDataList,
  createShopCardList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";
import resize from "../../layout/mixin/resize";



export default {
  name: "openCardWrite",
  mixins: [mixins],
  components: {
    EnTableLayout,
    XDialog,
  },
  computed: {
    filterdouble () {
      if (!this.double_choice_mall_open) {
        this.cardTypes.splice(2, 1)
      }
      return this.cardTypes;
    }
  },
  data () {
    return {
      timeNodes: 1723727330,
      customerData: {},//客户账户信息
      companyOption: [],//公司列表
      openingBankOption: [],//银行列表
      bankAccountOption: [],//银行账号列表
      double_choice_mall_open: true,//是否开启双选商城
      recordDialog: $xDialog.create({
        title: "核销记录",
        width: "740px",
        disableConfirm: true,
        disableCancel: true,
      }),
      oldOperDialog: $xDialog.create({
        title: "核销操作",
        width: "500px",
        beforeConfirm: () => {
          this.$refs["cancelAterVerificationForm"].validate((valid) => {
            if (valid) {
              let params = {
                client_name: this.cancelAterVerificationForm.client_name,
                change_price: this.cancelAterVerificationForm.change_price,
                ids: this.cancelAterVerificationForm.ids,
                trade_type: this.params.trade_type,
              };
              if (this.params.trade_type === "2") {
                params.sn = this.cancelAterVerificationForm.sn;
                params.remark = this.cancelAterVerificationForm.refund_remark;
              } else {
                params.sell_refund_record_sn =
                  this.cancelAterVerificationForm.sell_refund_record_sn;
                params.remark = this.cancelAterVerificationForm.payable_remark;
              }
              if (['1', '2'].includes(this.activeName)) {
                API_order.editCurrPrice(params).then((res) => {
                  console.log(res);

                  if (res.code == 200) {
                    this.oldOperDialog.dismiss();
                    this.$message.success("核销成功")
                    this.GET_OrderList(this.params);
                  } else {
                    this.$message.error(res.massage)
                  }
                });
              } else {
                checkMember({ sn: this.cancelAterVerificationForm.sn, type: 3 }).then(res => {
                  const form = {
                    sell_trade_sn: this.cancelAterVerificationForm.sn,
                    change_price: this.cancelAterVerificationForm.change_price,
                    bank_name: this.cancelAterVerificationForm.bank_name,
                    bank_account: this.cancelAterVerificationForm.bank_account,
                    company_name: this.cancelAterVerificationForm.company_name,
                    customer_id: this.cancelAterVerificationForm.customer_id,
                  }
                  rechargeUnderWrite(form).then((res) => {
                    this.$message.success("核销成功")
                    this.operDialog.dismiss();
                    this.GET_OrderList(this.params);
                  });
                }).catch(err => {
                })
              }
            } else {
              console.log("error submit!!");
            }
          });
          return false;
        },
      }),
      operDialog: $xDialog.create({
        title: "核销操作",
        width: "600px",
        beforeConfirm: () => {
          this.$refs["cancelAterVerificationForm"].validate((valid) => {
            if (valid) {
              const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.9)'
              });
              let params = {
                client_name: this.cancelAterVerificationForm.client_name,
                change_price: this.cancelAterVerificationForm.change_price,
                bank_name: this.cancelAterVerificationForm.bank_name,
                bank_account: this.cancelAterVerificationForm.bank_account,
                company_name: this.cancelAterVerificationForm.company_name,
                ids: this.cancelAterVerificationForm.ids,
                trade_type: this.params.trade_type,
              };
              if (this.params.trade_type === "2") {
                params.sn = this.cancelAterVerificationForm.sn;
                params.remark = this.cancelAterVerificationForm.refund_remark;
              } else {
                params.sell_refund_record_sn =
                  this.cancelAterVerificationForm.sell_refund_record_sn;
                params.remark = this.cancelAterVerificationForm.payable_remark;
              }
              if (['1', '2'].includes(this.activeName)) {
                API_order.editCurrPrice(params).then((res) => {
                  if (res.code == 200) {
                    this.operDialog.dismiss();
                    loading.close();
                    this.$message.success("核销成功")
                    this.GET_OrderList(this.params);
                  } else {
                    this.$message.error(res.massage)
                  }
                });
              } else {
                checkMember({ sn: this.cancelAterVerificationForm.sn, type: 3 }).then(res => {
                  const form = {
                    sell_trade_sn: this.cancelAterVerificationForm.sn,
                    change_price: this.cancelAterVerificationForm.change_price,
                    bank_name: this.cancelAterVerificationForm.bank_name,
                    bank_account: this.cancelAterVerificationForm.bank_account,
                    company_name: this.cancelAterVerificationForm.company_name,
                    customer_id: this.cancelAterVerificationForm.customer_id,
                    remark: this.cancelAterVerificationForm.remark,
                  }
                  rechargeUnderWrite(form).then((res) => {
                    this.$message.success("核销成功")
                    loading.close();
                    this.operDialog.dismiss();
                    this.GET_OrderList(this.params);
                  });
                }).catch(err => {
                })
              }
              setTimeout(() => {
                loading.close();
              }, 10000);
            } else {
              console.log("error submit!!");
            }
          });
          return false;
        },
      }),
      operCardDialog: $xDialog.create({
        width: "800px",
      }),
      remarkDialog: $xDialog.create({
        title: "备注说明",
        width: "500px",
        beforeConfirm: () => this.updateRemark(),
      }),
      activeName: "1",
      remark: "",
      // 列表loading状态
      loading: false,
      // 导出loading状态
      importLoading: false,
      importLoading1: false,
      // 核销列表
      recordTable: [],
      // 列表选中
      tempList: [],
      // 列表分页数据
      pageData: { data: [] },
      // 卡列表
      cardList: [],
      // 核销表单
      cancelAterVerificationForm: {
        bank_account: '',
        back_name: '',
        company_name: '',
      },
      // 卡号区间校验判断
      IntervalCheck: "",
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        trade_type: "1",
      },
      // 搜索数据
      advancedForm: {
        app_type_shop_type_flag: "",
        type: "sell_refund_record_sn",
        // 单据状态
        trade_status: "",
        status: "",
        // 款状态
        pay_status: "",
        refund_status: "",
        // 时间
        order_time_range: [],
        trade_source: '',//单据来源
        shop_type: '',//来源应用
        // 关键字
        keywords: "",
        cardCodeStart: "",
        cardCodeEnd: "",
      },
      rules: {
        change_price: [
          { required: true, message: '请输入核销金额', trigger: "blur" },
          {
            required: true,
            validator: this.validatorAccountNumber,
            trigger: "blur",
          },
        ],
        company_name: [
          { required: true, message: '请选择收款公司', trigger: 'change' }
        ],
        bank_name: [
          { required: true, message: '请选择开户银行', trigger: 'change' }
        ],
        bank_account: [
          { required: true, message: '请选择银行账户', trigger: 'change' }
        ],
      },
    };
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.getList()
    this.getEcaList();//获取所有公司列表
  },

  methods: {
    // 判断核销金额是否超出范围
    validatorAccountNumber (rule, value, callback) {
      let that = this;
      const thatForm = that.cancelAterVerificationForm;
      if (!value) {
        return callback(new Error("核销金额为必填项"));
      } else if (!RegExp.money.test(value)) {
        return callback(new Error("请输入正确的金额"));
      } else {
        if (that.activeName === "1") {
          console.log(value + Number(thatForm.curr_payable_price));
          if (
            Number(value) + Number(thatForm.curr_payable_price) >
            thatForm.total_payable_price
          ) {
            return callback(new Error("核销金额需小于等于【应收金额减已收金额】"));
          } else {
            return callback();
          }
        } else if (that.activeName === "2") {
          if (
            Number(value) + Number(thatForm.curr_refund_price) >
            thatForm.after_discount_price
          ) {
            return callback(new Error("核销金额需小于等于【应退金额减已退金额】"));
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      }
    },
    /**获取企业下所有公司列表 */
    async getEcaList () {
      let res = await getEcaList({ page_no: 1, page_size: 99999 })
      this.companyOption = res.data;
    },
    //获取公司下所有银行
    async getCustomerAccountList (id) {
      this.cancelAterVerificationForm.bank_name = ''
      this.cancelAterVerificationForm.bank_account = ''
      let res = await getCustomerAccount(id)
      let arr = []
      res.enterprise_collecting_account_detail_dtolist.forEach(item => {
        let obj = {
          children: []
        }
        if (arr.some(k => k.bank_name == item.bank_name)) {
          arr.forEach(j => {
            if (j.bank_name == item.bank_name) {
              j.children.push({ bank_account: item.bank_account })
            }
          })
        } else {
          obj.bank_name = item.bank_name;
          obj.children.push({ bank_account: item.bank_account })
          arr.push(obj)
        }
      })
      this.openingBankOption = arr;
      console.log(arr);
      this.cancelAterVerificationForm.bank_name = this.openingBankOption[0].bank_name;
      this.bankAccountOption = this.openingBankOption[0].children;
      this.cancelAterVerificationForm.bank_account = this.bankAccountOption[0].bank_account;
      console.log(this.cancelAterVerificationForm.bank_account);
    },
    getCompanyName () {//公司名称选择事件
      const data = this.companyOption.find(res => res.company_name == this.cancelAterVerificationForm.company_name)
      this.getCustomerAccountList(data.id)
    },
    getBanckAccount () {//银行名称选择事件
      this.cancelAterVerificationForm.bank_account = ''
      const data = this.openingBankOption.find(res => res.bank_name == this.cancelAterVerificationForm.bank_name)
      if (data) {
        this.bankAccountOption = data.children;
        console.log(data.children);
        this.cancelAterVerificationForm.bank_account = this.bankAccountOption[0].bank_account;
      }
    },

    checkPrice () {
      let max = 99999;
      if (this.activeName == 1) {
        max = this.cancelAterVerificationForm.total_payable_price - this.cancelAterVerificationForm.curr_payable_price
      }
      if (this.activeName == 2) {
        max = this.cancelAterVerificationForm.after_discount_price - this.cancelAterVerificationForm.curr_refund_price;
      }
      // this.cancelAterVerificationForm.change_price = Math.max(0, Math.min(this.cancelAterVerificationForm.change_price, max))
    },
    PermissionToCheck () {
      return API_order.getShopExtAuth().then((res) => {
        if (res.open !== "OPEN") {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台"
          );
          setTimeout(() => {
            this.$store.getters.permission_routers.forEach((item) => {
              if (item.path === "/finance") {
                // 关闭菜单
                item.children.forEach((value, index) => {
                  if (value.path === "cancel-after-verification") {
                    item.children.splice(index, 1);
                    this.$router.push("/finance/enterprise-overview");
                  }
                });
              }
            });
          }, 3000);
        } else {
          return true;
        }
      });
    },
    // 卡号区间校验
    setIntervalCheck () {
      console.log(
        this.advancedForm.cardCodeStart,
        this.advancedForm.cardCodeEnd
      );
      const start = this.splitCard(this.advancedForm.cardCodeStart);
      const end = this.splitCard(this.advancedForm.cardCodeEnd);
      console.log(start, end);
      if (
        (start.CardPrefix === "" && end.CardPrefix === "") ||
        start.CardPrefix === end.CardPrefix
      ) {
        this.IntervalCheck = "";
      } else {
        return (this.IntervalCheck = "2");
      }
      if (Number(start.number) <= Number(end.number)) {
        this.IntervalCheck = "";
      } else {
        return (this.IntervalCheck = "1");
      }
    },
    splitCard (data) {
      const regular = /^[1-9]\d*|0$/;
      let CardPrefix = "";
      let number = 0;
      console.log(CardPrefix);
      if (data === "")
        return {
          CardPrefix: data,
          number,
          index: 0,
        };
      for (let index = 0; index < data.length; index++) {
        if (regular.test(data[index])) {
          console.log(data);
          CardPrefix = data.slice(0, index);
          number = data.slice(index);
          console.log(CardPrefix, number);
          return {
            CardPrefix,
            number,
            index,
          };
        } else if (index === data.length - 1) {
          console.log(data, 0, index);
          return {
            CardPrefix: data,
            number,
            index,
          };
        }
      }
    },
    // 核销金额校验
    changePrice (e) {
      // console.log(e);
      // this.cancelAterVerificationForm.change_price = e
      //   .match(/^-\d*(\.?\d{0,2})/g)[0]
      //   .slice(0, 16);
    },
    // 页签选择
    setType (el) {
      this.activeName = el.name;
      this.pageData.data = [];
      this.params = {
        page_no: 1,
        page_size: 20,
        trade_type: this.activeName,
      };
      this.IntervalCheck = "";
      this.tempList = [];
      this.advancedForm = {
        app_type_shop_type_flag: "",
        // 单据状态
        trade_status: "",
        status: "",
        // 款状态
        pay_status: "",
        refund_status: "",
        shop_type: '',//来源应用
        // 时间
        order_time_range: [],
        trade_source: '',//单据来源
        // 关键字
        keywords: "",
        cardCodeStart: "",
        cardCodeEnd: "",
        type: this.filterType(this.activeName),
      };
      if (this.activeName == 6) {

        const now = new Date();
        // 将当前日期设置为 0 点
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        // 获取当前月份减 1
        now.setMonth(now.getMonth() - 1);
        const lod_time = now
        const new_time = new Date()
        // 将当前日期设置为 0 点
        new_time.setHours(0);
        new_time.setMinutes(0);
        new_time.setSeconds(0);
        new_time.setMilliseconds(0);
        this.advancedForm.order_time_range = [
          lod_time,
          new_time,
        ];
      }
      this.getList();
    },
    //格式化页签选中项
    filterType (activeName) {
      const template = {
        '1': "sell_refund_record_sn",
        '2': "sn",
        '3': "sell_refund_record_sn",
        '4': "recharge_sn",
        '5': "recharge_sn",
        '6': "customer_name",
      }
      return template[activeName]
    },
    // 选中数据
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    getList () {
      console.log(11111111111111);
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      switch (this.advancedForm.type) {

        case "sn":
          params.sn = params.keywords;
          break;
        case "sell_refund_record_sn":
          params.sell_refund_record_sn = params.keywords;
          break;
        case "client_name":
          params.client_name = params.keywords;
          break;
        case "link_name":
          params.link_name = params.keywords;
          break;
        case "sell_name":
          params.sell_name = params.keywords;
          break;
        case "shop_name":
          params.shop_name = params.keywords;
          break;
        case "customer_name"://往来对账客户名称
          params.customer_name = params.keywords;
          break;
        case "recharge_sn"://充值退款单号、充值销售单号
          params.recharge_sn = params.keywords;
          break;
        case "seller_name"://销售人员名称
          params.seller_name = params.keywords;
          break;
        case "operator_name"://制单人名称
          params.operator_name = params.keywords;
          break;
        case "remark"://备注信息
          params.remark = params.keywords;
          break;
        default:
          break;
      }
      let start_time = 'start_time';
      let end_time = 'end_time';
      if (this.activeName == '6') {//新来往对账核销
        start_time = 'bill_create_time_start';
        end_time = 'bill_create_time_end';
      }
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length !== 0
      ) {
        params[start_time] =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params[end_time] =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      } else {
        params[start_time] = ""
        params[end_time] = ""
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      this.GET_OrderList(params);
      // this.$refs.enTableLayout1.clearSelected();

    },
    spanMethod (opt) {
      switch (+this.activeName) {
        case 1:
          return spanMethod(opt, [2, 3, 4]);
        case 2:
          return spanMethod(opt, [3, 4, 5]);
        case 3:
          return spanMethod(opt, [2, 3, 4, 5, 6]);
        default:
          break;
      }
    },
    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      const cardStart = this.Trim(this.advancedForm.cardCodeStart);
      const cardEnd = this.Trim(this.advancedForm.cardCodeEnd);
      const start = this.splitCard(cardStart);
      const end = this.splitCard(cardEnd);
      if (
        (cardStart !== "" || cardEnd !== "") &&
        (this.advancedForm.keywords !== "" ||
          this.advancedForm.order_time_range.length !== 0 ||
          this.advancedForm.pay_status !== "" ||
          this.advancedForm.status !== "" ||
          this.advancedForm.trade_status !== "" ||
          this.advancedForm.refund_status !== "" ||
          this.advancedForm.app_type_shop_type_flag !== "")
      )
        return (
          (this.IntervalCheck = "3"),
          this.$message.error("卡号区间搜索不能和其他筛选条件同时使用")
        );
      if ((this.IntervalCheck = "3")) this.IntervalCheck = "";
      if (
        !(
          (cardStart !== "" && cardEnd !== "") ||
          (cardStart === "" && cardEnd === "")
        )
      )
        return (this.IntervalCheck = "5");

      if (
        cardStart.indexOf(" ") !== -1 ||
        cardStart.indexOf(" ") !== -1 ||
        start.CardPrefix.length > 5 ||
        start.number.length < 3 ||
        end.number.length < 3
      )
        return (this.IntervalCheck = "4");
      this.params.page_no = 1;
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      switch (this.advancedForm.type) {
        case "sn":
          params.sn = params.keywords;
          break;
        case "sell_refund_record_sn":
          params.sell_refund_record_sn = params.keywords;
          break;
        case "client_name":
          params.client_name = params.keywords;
          break;
        case "link_name":
          params.link_name = params.keywords;
          break;
        case "sell_name":
          params.sell_name = params.keywords;
          break;
        case "shop_name":
          params.shop_name = params.keywords;
          break;
        case "customer_name"://往来对账客户名称
          params.customer_name = params.keywords;
          break;
        case "recharge_sn"://充值退款单号、充值销售单号
          params.recharge_sn = params.keywords;
          break;
        case "seller_name"://销售人员名称
          params.seller_name = params.keywords;
          break;
        case "salesman_name"://销售人员名称
          params.salesman_name = params.keywords;
          break;
        case "operator_name"://制单人名称
          params.operator_name = params.keywords;
          break;
        case "remark"://备注信息
          params.remark = params.keywords;
          break;
        default:
          break;
      }
      let start_time = 'start_time';
      let end_time = 'end_time';
      if (this.activeName == '6') {//新来往对账核销
        start_time = 'bill_create_time_start';
        end_time = 'bill_create_time_end';
      }
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length !== 0
      ) {
        params[start_time] =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params[end_time] =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      this.GET_OrderList(params);
    },
    remove (arr, str) {
      var index = arr.indexOf(str);
      return arr.splice(index, 1);
    },
    //导出
    submitImport (type) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        const cardStart = this.Trim(this.advancedForm.cardCodeStart);
        const cardEnd = this.Trim(this.advancedForm.cardCodeEnd);
        const start = this.splitCard(cardStart);
        const end = this.splitCard(cardEnd);
        if (
          (cardStart !== "" || cardEnd !== "") &&
          (this.advancedForm.keywords !== "" ||
            this.advancedForm.order_time_range.length !== 0 ||
            this.advancedForm.pay_status !== "" ||
            this.advancedForm.status !== "" ||
            this.advancedForm.trade_status !== "" ||
            this.advancedForm.refund_status !== "" ||
            this.advancedForm.app_type !== "")
        )
          return (
            (this.IntervalCheck = "3"),
            this.$message.error("卡号区间搜索不能和其他筛选条件同时使用")
          );
        if ((this.IntervalCheck = "3")) this.IntervalCheck = "";
        if (
          !(
            (cardStart !== "" && cardEnd !== "") ||
            (cardStart === "" && cardEnd === "")
          )
        )
          return (this.IntervalCheck = "5");

        if (
          cardStart.indexOf(" ") !== -1 ||
          cardStart.indexOf(" ") !== -1 ||
          start.CardPrefix.length > 5 ||
          start.number.length < 3 ||
          end.number.length < 3
        )
          return (this.IntervalCheck = "4");
        this.params.page_no = 1;
        let params = {
          ...this.params,
          ...this.advancedForm,
        };
        switch (this.advancedForm.type) {
          case "sn":
            params.sn = params.keywords;
            break;
          case "sell_refund_record_sn":
            params.sell_refund_record_sn = params.keywords;
            break;
          case "client_name":
            params.client_name = params.keywords;
            break;
          case "link_name":
            params.link_name = params.keywords;
            break;
          case "sell_name":
            params.sell_name = params.keywords;
            break;
          case "shop_name":
            params.shop_name = params.keywords;
            break;
          case "customer_name"://往来对账客户名称
            params.customer_name = params.keywords;
            break;
          case "recharge_sn"://充值退款单号、充值销售单号
            params.recharge_sn = params.keywords;
            break;
          case "seller_name"://销售人员名称
            params.seller_name = params.keywords;
            break;
          case "operator_name"://制单人名称
            params.operator_name = params.keywords;
            break;
          case "remark"://备注信息
            params.remark = params.keywords;
            break;
          default:
            break;
        }
        let start_time = 'start_time';
        let end_time = 'end_time';
        if (this.activeName == '6') {//新来往对账核销
          start_time = 'bill_create_time_start';
          end_time = 'bill_create_time_end';
        }

        if (
          this.advancedForm.order_time_range &&
          this.advancedForm.order_time_range.length !== 0
        ) {
          params[start_time] =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          params[end_time] =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
        delete params.keywords;
        delete params.type;
        delete params.order_time_range;
        // let params = { ...this.params };
        if (type == 1) {
          params.ids = this.tempList.map((g) => {
            return g.id;
          });
          if (!params.ids.length)
            return this.$message.error("请先选择要导出的数据");
        }
        if (type == 0) {
          this.importLoading = true;
        } else {
          this.importLoading1 = true;
        }
        let orderFunc = "getCardsUnderWrite";
        let _list = [];
        let _fl_num = [];
        let templateName = "";
        switch (this.activeName) {
          case "1":
            templateName = "销售订单核销列表";
            _fl_num = [1, 2, 3];
            _list = [
              ["销售单据编号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需开卡总数", "card_num"],
              ["已开卡数量", "open_card_num"],
              ["应收金额", "total_payable_price"],
              ["已收金额", "curr_payable_price"],
              ['余额', 'balance'],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["付款状态", "pay_status"],
              ["单据状态", "trade_status"],
              ['单据来源', 'trade_source'],
              ["单据生成日期", "create_time"],
              ["收款备注", "payable_remark"],
            ];
            break;
          case "2":
            templateName = "销售退单核销列表";
            _fl_num = [2, 3, 4];
            _list = [
              ["销售退单编号", "sn"],
              ["关联销售单号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需退卡总数", "refund_num"],
              ["已退卡数量", "refund_card_num"],
              ["应退金额", "after_discount_price"],
              ["已退金额", "refunded_price"],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["退款状态", "refund_status"],
              ["单据状态", "status"],
              ['单据来源', 'trade_source'],
              ["单据生成日期", "create_time"],
              ["退款备注", "refund_remark"],
            ];
            break;
          case "3":
            templateName = "来往对账核销列表";
            _fl_num = [1, 2, 3, 4, 5];
            _list = [
              ["销售单据编号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需开卡总数", "card_num"],
              ["已开卡总数", "open_card_num"],
              ["需退卡总数", "refund_num"],
              ["已退卡总数", "curr_refund_num"],
              ["应收金额", "total_payable_price"],
              ["已收金额", "curr_payable_price"],
              ["应退金额", "total_refund_price"],
              ["已退金额", "curr_refund_price"],
              ["余额", "balance"],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["单据生成日期", "create_time"],
              ["备注信息", "remark"],
            ];
            break;
          default:
            break;
        }
        delete params.page_no;
        delete params.page_size;
        let tHeaders = _list.map((item) => item[0]);
        let filterVals = _list.map((item) => item[1]);

        let merges = [];
        let wscols = new Array(tHeaders.length).fill(20).map((n) => {
          return {
            wch: n,
          };
        });
        if (this.activeName == 4) {
          params.trade_type = 1;//单据类型 1充值 2扣款
          params.query_type = 1;//查询来源 1后台 2移动端
          params.page_no = 1;
          params.page_size = 99999;
          if (type == 1) {
            params.recharge_ids = params.ids.toString();
          }
          getRechargeRefundSalesList(params).then((res) => {
            const tHeaders = ['充值销售单号', '所属商城', '充值人数', '应收金额', '已收金额',
              '来源应用', '客户名称', '单据状态', '销售人员', '联系人名称', '制单人', '单据日期', '单据来源', '备注信息']
            const filterVals = ['sn', 'shop_name', 'recharge_number', 'discounts_total_money', 'discounts_total_money_two',
              'shop_type', 'customer_name', 'trade_status', 'seller_name', 'contacts_person', 'operator_name', 'create_time', 'trade_source', 'remark']

            res.data.forEach(item => {
              item.shop_type = item.shop_type == 1 ? '现金商城' : '双选商城'
              item.trade_status = this.getTradeTypeName(item.trade_status)
              item.create_time = Foundation.unixToDate(item.create_time)
              item.trade_source = item.trade_source == 1 ? '企业后台' : '移动端前台'
              item.discounts_total_money_two = item.trade_status == 0 ? 0 : item.trade_status == 3 ? 0 : item.discounts_total_money
            })

            handleDownload(res.data, tHeaders, filterVals, "充值销售单核销列表");
            this.importLoading = false;
            this.importLoading1 = false;
            this.$refs.enTableLayout.clearSelected();
          });

        } else if (this.activeName == 5) {
          params.trade_type = 2;//单据类型 1充值 2扣款
          params.query_type = 1;//查询来源 1后台 2移动端
          params.page_no = 1;
          params.page_size = 99999;
          if (type == 1) {
            params.recharge_ids = params.ids.toString();
          }
          getRechargeRefundSalesList(params).then((res) => {
            const tHeaders = ['充值退款单号', '所属商城', '扣款人数', '应退金额', '已退金额',
              '来源应用', '客户名称', '单据状态', '销售人员', '联系人名称', '制单人', '单据日期', '单据来源', '备注信息']
            const filterVals = ['sn', 'shop_name', 'recharge_number', 'discounts_total_money', 'discounts_total_money_two',
              'shop_type', 'customer_name', 'trade_status', 'seller_name', 'contacts_person', 'operator_name', 'create_time', 'trade_source', 'remark']

            res.data.forEach(item => {
              item.shop_type = item.shop_type == 1 ? '现金商城' : '双选商城'
              item.trade_status = this.getTradeTypeName(item.trade_status)
              item.create_time = Foundation.unixToDate(item.create_time)
              item.trade_source = item.trade_source == 1 ? '企业后台' : '移动端前台'
              item.discounts_total_money_two = item.trade_status == 0 ? 0 : item.trade_status == 3 ? 0 : item.discounts_total_money
            })
            handleDownload(res.data, tHeaders, filterVals, "充值退款单核销列表");
            this.importLoading = false;
            this.importLoading1 = false;
            this.$refs.enTableLayout.clearSelected();
          });
        } else {
          API_order[orderFunc](params)
            .then((res) => {
              let listkey = this.activeName == 2 ? "shop_refund_records" : "shop_account_sell_list"
              let list = changeDataList(res.data, listkey, [
                "shop_name",
                "card_num",
                "open_card_num",
                "refund_num",
                "refund_card_num",
                "curr_refund_num",
                "need_refund_price"
              ]);
              let data = list.map((item, index) => {
                if (item.span > 1) {
                  let arr = new Array(tHeaders.length)
                    .fill(0)
                    .map((a, i) => {
                      if (_fl_num.includes(i)) return null;
                      return {
                        s: { r: index + 1, c: i },
                        e: { r: index + item.span, c: i },
                      };
                    })
                    .filter((a) => a);
                  merges.push(...arr);
                }

                item.create_time = Foundation.unixToDate(
                  item.create_time,
                  "yyyy-MM-dd hh:mm:ss"
                );
                let ps1 = item.total_payable_price < item.curr_payable_price ? "(超付)" : ""
                let rs1 = item.after_discount_price < item.refunded_price ? "(超退)" : ""

                item.pay_status = this.getPayTypeName(item.pay_status) + ps1;
                item.refund_status = this.getRefundTypeName(item.refund_status) + rs1;
                item.trade_status = this.getTradeTypeName(item.trade_status);
                item.status = this.getTradeTypeName(item.status);
                item.balance = Foundation.subtraction(item.total_payable_price, item.curr_payable_price);//余额

                item.trade_source = item.trade_source == 1 ? '企业后台' : '移动端前台';
                item.app_type_shop_type_flag = this.getAppTypeName(item.app_type_shop_type_flag);
                item.curr_refund_num = item.curr_refund_num || 0;
                item.refund_card_num = item.refund_card_num || item.curr_refund_num || 0;
                item.refund_num = item.refund_num || item.card_num || 0;
                let obj = {};
                tHeaders.forEach((key, i) => (obj[key] = item[filterVals[i]]));
                return obj;
              });

              downloadExcel(
                {
                  data,
                  merges,
                  wscols,
                },
                templateName
              );
              // handleDownload(list, tHeaders, filterVals, templateName, true);
              this.importLoading = false;
              this.importLoading1 = false;
            })
            .catch((res) => {
              this.importLoading = false;
              this.importLoading1 = false;
            });
        }
      });
    },
    Trim (str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    GET_OrderList (params) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        if (this.activeName == '6') {//新来往对账核销列表
          getCustomerAccountVerificationList(params).then(res => {
            this.pageData = res;
            console.log(this.pageData.data, 'this.pageData.data');
          })
        } else if (['1', '2', '3'].includes(this.activeName)) {//销售订单核销列表、销售退单核销列表、旧来往对账核销列表
          API_order.getCardsUnderWrite(params).then((res) => {
            this.pageData = res;
            let listkey = this.activeName == 2 ? "shop_refund_records" : "shop_account_sell_list"
            this.pageData.data = changeDataList(
              res.data,
              listkey,
              [
                "shop_name",
                "card_num",
                "open_card_num",
                "refund_num",
                "refund_card_num",
                "curr_refund_num",
                "need_refund_price"
              ]
            );
          });
        } else if (['4', '5'].includes(this.activeName)) {//获取销售充值/扣款单列表
          let newParams = {
            ...params
          }
          newParams.query_type = 1;
          newParams.trade_type = this.activeName == '4' ? '1' : '2';
          getRechargeRefundSalesList(newParams).then(res => {
            this.pageData = res;
          })
        }
      });
    },
    //查看新来往对账核销
    handleDetail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        path: `/finance/verificationManagement/writeOffOpenCardDetail/${row.customer_id}`,
        query: {
          order_time_range: JSON.stringify(this.advancedForm.order_time_range)
        }
      });
    },
    // 查看
    getInfoUnderWrite (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        const { id } = row;
        if (this.activeName == 1) {
          this.$router.push({
            name: "verificationOrderDetail",
            query: {
              id,
            },
          });
        }
        if (this.activeName == 2) {
          this.$router.push({
            name: "verificationRefundDetail",
            query: {
              id,
            },
          });
        }
        if (this.activeName == 4) {
          this.$router.push({
            name: "verificationRechargeSalesDetail",
            query: {
              id: row.sn
            },
          });
        }
        if (this.activeName == 5) {
          this.$router.push({
            name: "verificationRechargeRefundDetail",
            query: {
              id: row.sn
            },
          });
        }
      });
    },
    // 核销记录
    getRemarkUnderWrite (row) {
      let params = {
        trade_type: this.params.trade_type,
      };
      console.log(row, 'row');
      console.log(this.params.trade_type, 'row2');
      console.log(typeof this.params.trade_type, 'row3');
      if (this.params.trade_type === "2" || this.params.trade_type === "5") {//逆向单核销记录
        params.sell_refund_record_sn = row.sn
        params.sn = row.sn;
        API_order.getRefundRemarkUnderWrite(params).then((res) => {
          this.recordTable = res;
          this.recordDialog.display();
        });
      } else {//正向单核销记录
        if (['4'].includes(this.activeName)) {
          params.sell_refund_record_sn = row.sn
        } else {
          params.sell_refund_record_sn = row.sell_refund_record_sn;
        }
        API_order.getRemarkUnderWrite(params).then((res) => {
          this.recordTable = res;
          this.recordDialog.display();
        });
      }
    },
    // 核销回显
    async cancelAterVerification (row, type) {
      let params = {};
      this.cancelAterVerificationForm = {
        ...row,
        change_price: "",
        payable_remark: "",
        refund_remark: "",
        bank_account: '',
        bank_name: '',
        company_name: '',
        remark: ''
      };

      if (this.activeName === "1") {
        params.sell_refund_record_sn = row.sell_refund_record_sn;
        API_order.getTradeIds(params).then((res) => {
          this.cancelAterVerificationForm.ids = res.map((g) => {
            return g.id;
          });
          this.cancelAterVerificationForm.shopId = res[0].shop_id
        });
      } else if (this.activeName === "2") {
        params.sn = row.sn;
        API_order.getRefundIds(params).then((res) => {
          this.cancelAterVerificationForm.ids = res.map((g) => {
            return g.id;
          });
          this.cancelAterVerificationForm.shopId = res[0].shop_id
        });
      }
      else if (['4', '5'].includes(this.activeName)) {
        this.cancelAterVerificationForm.customer_id = row.customer_id;//客户id
        this.cancelAterVerificationForm.sell_trade_sn = row.sn;//单据编号
        this.cancelAterVerificationForm.change_price = row.discounts_total_money
        this.cancelAterVerificationForm.shopId = row.shop_id
      }
      if (row.create_time < this.timeNodes) {
        this.oldOperDialog.display();
      } else {
        this.customerData = {};
        //查询客户账户信息
        const customerData = await getCustomerAll(row.customer_id);
        this.customerData = customerData;
        if (customerData.customer_customized_project_dtolist.length > 0) {
          const showzedDiscount = customerData.customer_customized_project_dtolist.find(item => this.cancelAterVerificationForm.shopId == item.shop_id)
          customerData.showzedDiscount = showzedDiscount;
        }
        this.cancelAterVerificationForm.company_name = this.companyOption[0]?.company_name;
        if(this.companyOption.length>0) this.getCompanyName();
        this.operDialog.display();
      }
    },
    cardSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          rowspan: row.span[0],
          colspan: 1,
        };
      }
      if (columnIndex == 1) {
        return {
          rowspan: row.span[1],
          colspan: 1,
        };
      }
    },
    // 开卡退卡回显
    getCloseCardsTypeChange (row) {
      console.log(row);
      let params = {
        trade_type: this.params.trade_type,
      };
      if (this.params.trade_type === "2") {
        params.sn = row.sn;
        API_order.getCloseCardsTypeChange(params).then((res) => {
          // 处理cardList成多行
          this.cardList = createShopCardList(res);
          params.ids = res.map((g) => {
            return g.id;
          });
          this.operCardDialog.display({
            title: "退卡操作",
            beforeConfirm: () => this.closeAllCards(params),
          });
        });
      } else {
        params.sell_refund_record_sn = row.sell_refund_record_sn;
        API_order.getOpenCardsTypeChange(params).then((res) => {
          // 处理cardList成多行
          let _cardList = createShopCardList(res);
          this.cardList = _cardList;
          params.ids = res.map((g) => {
            return g.id;
          });
          this.operCardDialog.display({
            title: "开卡操作",
            beforeConfirm: () => this.openAllCards(params),
          });
        });
      }
    },
    // 核销确定
    editCurrPrice (formName) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              client_name: this.cancelAterVerificationForm.client_name,
              change_price: this.cancelAterVerificationForm.change_price,
              trade_type: this.params.trade_type,
              remark: this.cancelAterVerificationForm.remark,
            };
            if (this.params.trade_type === "2") {
              params.sn = this.cancelAterVerificationForm.sn;
            } else {
              params.sell_refund_record_sn =
                this.cancelAterVerificationForm.sell_refund_record_sn;
            }
            API_order.editCurrPrice(params).then((res) => {
              if (res.code !== 200) return this.$message.error(res.massage);
              this.$message.success(res.massage);
              this.GET_OrderList(this.params);
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      });
    },
    // 开卡
    openAllCards (params) {
      this.$confirm(`确定要对该卡号段进行开卡（激活）操作吗？`, "提示", {
        type: "warning",
      }).then(() => {
        this.PermissionToCheck().then((isRes) => {
          API_order.openAllCards(params).then((res) => {
            if (res.code !== 200) return this.$message.error(res.massage);
            this.$message.success(res.massage);
            this.GET_OrderList(this.params);
          });
        });
      });
    },
    // 退卡
    closeAllCards (params) {
      this.$confirm(`确定要对该卡号段进行退卡（禁用）操作吗？`, "提示", {
        type: "warning",
      }).then(() => {
        this.PermissionToCheck().then((isRes) => {
          if (!isRes) return;
          API_order.closeAllCards(params).then((res) => {
            if (res.code !== 200) return this.$message.error(res.massage);
            this.$message.success(res.massage);
            this.GET_OrderList(this.params);
          });
        });
      });
    },
    // 备注
    updateRemark (row) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        let params = {
          sell_refund_record_sn:
            this.cancelAterVerificationForm.sell_refund_record_sn,
          remark: this.remark,
        };
        API_order.updateRemark(params).then((res) => {
          if (res.code !== 200) return this.$message.error(res.massage);
          this.$message.success(res.massage);
          this.GET_OrderList(this.params);
          this.remark = "";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.writeOffOpenCard {
  background: #fff;

  .el-tab-pane {
    height: calc(100vh - 140px);
    min-height: 640px;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-bottom: 10px !important;
}

/deep/ .el-table__fixed-body-wrapper {
  top: 34.5px !important;
}

/deep/ .en-table-layout .en-table-header {
  padding: 0;
}

/deep/ .el-tabs__header {
  margin: 0;
}

.cardInterval {
  position: absolute;
  top: 26px;
  color: rgb(251, 65, 65);
  width: 400px;
  font-size: 10px;
}

p {
  margin-bottom: 5px;
}

.refund-no-num {
  color: #999;
  font-size: 12px;
  margin-bottom: 0;
}

.c-a-form {
  .el-form-item {
    margin-bottom: 8px;
  }

  /deep/ .el-form-item__content {
    width: 100%;
  }
}

.col-auto {
  /deep/ .el-form-item__error {
    z-index: 8;
  }
}
</style>
